import React from 'react';
import styled from 'styled-components';
import { media } from '../../../globalStyles';
import wearImg from './../../../assets/img/android-wear.png';
import tvImg from './../../../assets/img/android-tv.png';
import phoneImg from './../../../assets/img/android-phone.png';
import wearImgMobile from './../../../assets/img/android-wear-mobile.png';
import tvImgMobile from './../../../assets/img/android-tv-mobile.png';
import phoneImgMobile from './../../../assets/img/android-phone-mobile.png';

const StyledServices = styled.ul`
	display: flex;
	padding: 113px 14px 121px 14px;
	justify-content: center;
	align-items: flex-start;
	${media.maxSm`
		padding: 50px 0 80px;
	`};
	.android-service {
		width: 167px;
		height: 167px;
		background-color: white;
		border-radius: 36px;
		box-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 0 40px;
		${media.maxSm`
			box-shadow: none;
			margin: 0 10px;
			height: 90px;
	`};
		img {
			width: 100%;
		}
	}
`;

const Services = () => (
	<StyledServices className="android-services">
		<li className="android-service">
			<figure>
				<img className="hidden-sm" src={wearImg} alt="android wear watch" />
				<img
					className="visible-sm"
					src={wearImgMobile}
					alt="small android wear watch"
				/>
			</figure>
		</li>
		<li className="android-service">
			<figure>
				{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
				<img className="hidden-sm" src={tvImg} alt="android tv image" />
				{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
				<img className="visible-sm" src={tvImgMobile} alt="android tv image" />
			</figure>
		</li>
		<li className="android-service">
			<figure>
				{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
				<img className="hidden-sm" src={phoneImg} alt="android phone image" />
				{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
				<img
					className="visible-sm"
					src={phoneImgMobile}
					alt="android phone image"
				/>
			</figure>
		</li>
	</StyledServices>
);

export default Services;
