import React from 'react';
import styled from 'styled-components';
import Particles from 'react-particles-js';
import verticalStripeImg from '../../../assets/img/vertical-stripes.png';
import P from '../../_common/P';
import H2 from '../../_common/H2';
import H3 from '../../_common/H3';
import { media, fonts, colors } from '../../../globalStyles';
import AnimatedOnScroll from '../../../components/_common/AnimatedOnScroll';
import { Link } from 'gatsby';

const StyledDevelopmentCycle = styled.section`
	position: relative;
	background: ${colors.blueDark};
	overflow: hidden;
	.container-fluid {
		display: flex;
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		${media.maxMd`
			flex-direction: column;
		`}
		& > div {
			position: relative;
			width: 50%;
			${media.maxMd`
				width: 100%;
			`}
			${H2} {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10%;
			color: ${colors.white};
			font-family: ${fonts.openSans};
			font-size: 50px;
			font-weight: 400;
			line-height: 68px;
			text-align: right;
			${media.maxMd`
				position: static;
			    text-align: center;
			    font-size: 36px;
			    ine-height: 48px;
			    transform: translate(0);
			`}
			${media.maxMd`
				font-size: 30px;
			    line-height: 44px;
			`}
			span {
				border-bottom: 1px solid ${colors.orange};
				${media.maxSm`
					border-bottom: none;
				`}
			}
		}
		hr {
			width: 95px;
		    height: 1px;
		    margin: 0 auto;
		    background: ${colors.orange};
		    border: none;
			}
		}
	}
	.development-steps-list {
		width: 50%;
		padding: 32px 0 0 118px;
		${media.maxMd`
			width: 100%;
			padding-left: 0;
		`}
		${media.minMd`
			background: url(${verticalStripeImg}) 0 2% repeat-y;
		`}
		&>div:not(:last-of-type) .development-step {
			border-bottom: 1px solid ${colors.borderBottom};
		}
		.development-step {
			margin-bottom: 36px;
			${H3} {
				color: white;
				font-family: ${fonts.openSans};
				font-size: 28px;
				font-weight: 400;
				position: relative;
				${media.maxSm`
					font-size: 22px;
				`}
				&::before {
					content: '';
					width: 44px;
					height: 44px;
					background-color: ${colors.orange};
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
				}
				span {
					position: relative;
					z-index: 1000;
					padding: 7px 0 0 4px;
					display: block;
				}
			}
			p {
				text-align: left;
				color: ${colors.blueText};
				font-family: ${fonts.openSans};
				font-size: 15px;
				font-weight: 400;
				line-height: 24px;
				padding-top: 24px;
				padding-bottom: 30px;
			}
			a{
				text-decoration: none;
				color: ${colors.white};
				font-family: ${fonts.openSansBold};
			}
		}
		
	}
	.particles-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		canvas {
			width: 100%;
			height: 100%;
		}
		${media.maxSm`
			display: none;
		`}
	}
	}
`;

export const particlesConfig = {
	particles: {
		number: {
			value: 80,
			density: {
				enable: true,
				value_area: 2800,
			},
		},
		color: {
			value: '#ffffff',
		},
		shape: {
			type: 'circle',
			stroke: {
				width: 0,
				color: '#000000',
			},
			polygon: {
				nb_sides: 1,
			},
		},
		opacity: {
			value: 0.5,
			random: false,
			anim: {
				enable: false,
				speed: 1,
				opacity_min: 0.1,
				sync: false,
			},
		},
		size: {
			value: 1,
			random: true,
			anim: {
				enable: false,
				speed: 60,
				size_min: 0.1,
				sync: false,
			},
		},
		line_linked: {
			enable: true,
			distance: 650,
			color: '#ffffff',
			opacity: 0.1,
			width: 1,
		},
		move: {
			enable: true,
			speed: 5,
			direction: 'none',
			random: false,
			straight: false,
			out_mode: 'out',
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200,
			},
		},
	},
	interactivity: {
		detect_on: 'canvas',
		events: {
			onhover: {
				enable: false,
				mode: 'repulse',
			},
			onclick: {
				enable: true,
				mode: 'push',
			},
			resize: true,
		},
		modes: {
			grab: {
				distance: 800,
				line_linked: {
					opacity: 1,
				},
			},
			bubble: {
				distance: 800,
				size: 80,
				duration: 2,
				opacity: 0.8,
				speed: 3,
			},
			repulse: {
				distance: 400,
				duration: 0.4,
			},
			push: {
				particles_nb: 4,
			},
			remove: {
				particles_nb: 2,
			},
		},
	},
	retina_detect: true,
};

const DevelopmentSteps = () => (
	<StyledDevelopmentCycle className="development-steps">
		<div className="container-fluid">
			<AnimatedOnScroll animateIn="fadeInRight" animateOut="fadeOutRight">
				<H2>
					Full-Cycle Android App <span>Develop</span>ment
				</H2>
				<hr className="visible-sm" />
			</AnimatedOnScroll>
			<ul className="development-steps-list">
				<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
					<li className="development-step">
						<H3>
							<span>Application Development</span>
						</H3>
						<P>
							The Android phone, backed by Google, is used on more than 2
							billion devices around the globe. That’s a lot of people! We want
							you to reach as many as possible. Our expert developers design an
							app the captures the personality of your brand, aligns with your
							marketing goals, and makes people take notice.
						</P>
					</li>
				</AnimatedOnScroll>
				<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
					<li className="development-step">
						<H3>
							<span>QA & Testing</span>
						</H3>
						<P>
							It’s not finished until it’s perfect. Because we are a
							<Link to={'/technologies-full-stack'}>
								{' '}
								full-stack development{' '}
							</Link>{' '}
							company, we provide end-to-end services – from idea to development
							to deployment. Our quality control specialists don’t miss a thing.
							By performing rigorous QA tests, we ensure your app works the way
							it should all the time.
						</P>
					</li>
				</AnimatedOnScroll>
				<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
					<li className="development-step">
						<H3>
							<span>Maintenance & Support</span>
						</H3>
						<P>
							Even after we launch your new app, we are still here to provide
							ongoing support. Things change and hiccups happen. If they do, the
							team at <b>PixelTeh</b> responds quickly to your needs and makes
							the adjustments necessary. We want your dream Android custom app
							to fulfill your wildest wishes both now and into the future.
						</P>
					</li>
				</AnimatedOnScroll>
			</ul>
		</div>
		<Particles className="particles-container" params={particlesConfig} />
	</StyledDevelopmentCycle>
);
export default DevelopmentSteps;
