import React from 'react';
import { shape, string } from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import DescriptionBlock from '../components/TechnologiesAndroidPage/PageDescription';
import Typography from '../components/TechnologiesAndroidPage/Typography/';
import DevelopmentSteps from '../components/TechnologiesAndroidPage/DevelopmentSteps';
import ProcessList from '../components/TechnologiesAndroidPage/ProcessList';
import SubmitQuote from '../components/SubmitQuote';
import CompleteSolution from '../components/TechnologiesAndroidPage/CompleteSolution';
import Stack from '../components/Stack';

import java from '../assets/img/java-icon.png';
import firebase from '../assets/img/firebase-icon.png';
import crashlytics from '../assets/img/crashlytics-icon.png';
import glide from '../assets/img/glide-icon.png';
import cPlus from '../assets/img/c-plus-plus-icon.png';
import realm from '../assets/img/realm.png';
import unity from '../assets/img/unity3d.png';
import open from '../assets/img/open-logo.png';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { android } from '../seoConfig';

const stackImages = [
	{
		icon: java,
		alt: 'gray java logo',
	},
	{
		icon: firebase,
		alt: 'gray firebase logo',
	},
	{
		icon: crashlytics,
		alt: 'crashlytics logo',
	},
	{
		icon: glide,
		alt: 'glide logo',
	},
	{
		icon: cPlus,
		alt: 'C++ logo',
	},
	{
		icon: realm,
		alt: 'realm logo',
	},
	{
		icon: unity,
		alt: 'unity3d logo',
	},
	{
		icon: open,
		alt: 'opengl logo',
	},
];

const TechnologiesAndroidPage = ({ location }) => {
	const img = useHeroPictureTechnologies();
	return (
		<PageLayout location={location} seoConfig={android}>
			<SubpageTopCover
				backgroundImg={img}
				altText={'pixelteh team with computers'}
			>
				Android Development
			</SubpageTopCover>
			<DescriptionBlock />
			<Typography />
			<DevelopmentSteps />
			<ProcessList />
			<SubmitQuote />
			<CompleteSolution />
			<Stack title="Android Apps Technology Stack" images={stackImages} />
		</PageLayout>
	);
};

TechnologiesAndroidPage.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
};
export default TechnologiesAndroidPage;
