import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import CenterAnimatedButton from '../../_common/CenterAnimatedButton';
import Solution from './Solution';
import { media } from '../../../globalStyles';
import mediaBox from '../../../assets/img/media-box.png';
import mediaMobile from '../../../assets/img/media-mobile.png';
import ecommerce from '../../../assets/img/ecommerce.png';
import ecommerceMobile from '../../../assets/img/ecommerce-mobile.png';
import gps from '../../../assets/img/GPS.png';
import gpsMobile from '../../../assets/img/gps-mobile.png';
import enterprise from '../../../assets/img/enterprise.png';
import enterpriseMobile from '../../../assets/img/enterprise-mobile.png';

const StyledCompleteSolution = styled.div`
	padding-bottom: 20px;
	.solutionsList {
		display: flex;
		justify-content: space-between;
		max-width: 1495px;
		padding-left: 0;
		margin: 0 auto;
		margin-bottom: 77px;
		${media.maxSm`
			padding-bottom: 20px;
			margin-bottom: 0;
			argin-bottom: 0;
		    align-items: center;
		    flex-wrap: wrap;
		`}
		${media.maxXs`
			justify-content: center;
		`};
	}
`;

const CompleteSolution = () => {
	return (
		<ContentSection
			fluid
			title="Complete App Solutions For Your Business"
			subtitle="Reach the world and grow your brand!"
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<StyledCompleteSolution>
				<ul className="solutionsList">
					<Solution
						name="Media & content apps"
						description="Customized Android apps for streaming videos, audio, podcasts, and much more."
						mainImg={mediaBox}
						mobileImg={mediaMobile}
						alt={'phone play list'}
					/>
					<Solution
						name="Ecommerce Apps"
						description="Manage your business on the go. You’ll never miss a chance to make a sale."
						mainImg={ecommerce}
						mobileImg={ecommerceMobile}
						alt={'shopping cart on mobile phone'}
					/>
					<Solution
						name="Gps & navigation apps"
						description="These apps make shopping, traveling, dining, and social networking easy."
						mainImg={gps}
						mobileImg={gpsMobile}
						alt={'package delivery app'}
					/>
					<Solution
						name="Enterprise Mobility"
						description="Help employees report, track, and work effectively with a custom enterprise app."
						mainImg={enterprise}
						mobileImg={enterpriseMobile}
						alt={'enterprise mobility app'}
					/>
				</ul>
				<CenterAnimatedButton
					linkTo="/portfolio"
					gaLabel="technologies-android Start a project with us"
				>
					Start a project with us
				</CenterAnimatedButton>
			</StyledCompleteSolution>
		</ContentSection>
	);
};

export default CompleteSolution;
