import React from 'react';
import styled from 'styled-components';
import Services from './Services';
import ServicesList from './ServicesList';
import androidRedImg from './../../../assets/img/android-app-alt.png';

import { media } from '../../../globalStyles';

const StyledTypography = styled.section`
	.img-cover {
		max-width: 313px;
		margin: 0 auto;
		margin-top: 88px;
		${media.maxSm`
		    max-width: 197px;
			margin-top: 0;
	`}
		img {
			${media.maxSm`
		    width: 100%;
	`}
		}
	}
`;

const Typography = () => (
	<StyledTypography className="android-apps">
		<div className="container-fluid">
			<figure className="img-cover">
				<img src={androidRedImg} alt="orange android head" />
			</figure>
			<Services />
			<ServicesList />
		</div>
	</StyledTypography>
);

export default Typography;
