import React from 'react';
import styled from 'styled-components';
import P from '../../_common/P';
import H4 from '../../_common/H4';
import hrImg from '../../../assets/img/hr-img.png';
import { colors, media } from '../../../globalStyles';
import bottomBorder from '../../../assets/img/bottom-border.png';

const StyledServicesList = styled.ul`
	display: flex;
	justify-content: space-between;
	padding-bottom: 107px;
	padding-left: 0;
	${media.tablet`
		    flex-direction: column;
            padding-bottom: 0;
	`};
	${media.maxXs`
		    align-items: center;
	`}
	.android-service {
		width: 20%;
		text-align: center;
		${media.tablet`
		    width: 100%;
	`}
		${media.maxXs`
		    max-width: 336px;
	`}
		&:not(:last-of-type) {
			${media.tablet`
		        background: url(${bottomBorder}) repeat-x 0 100%;
		        margin-bottom: 20px;
	`}
		}
	}
	${H4} {
		color: ${colors.blackTitle};
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 21px;
	}
	hr {
		width: 85px;
		height: 14px;
		margin: 0 auto;
		border: none;
		${media.tablet`
		    display: none;
	`};
	}
	${P} {
		color: ${colors.greyItalicTitle};
		font-style: italic;
		font-weight: 300;
		padding: 30px 0;
		font-size: 15px;
		line-height: 30px;
		${media.tablet`
		    padding-top: 0;
	`};
	}
`;

const ServicesList = () => (
	<StyledServicesList className="android-services-list">
		<li className="android-service">
			<H4>Low Development Cost</H4>
			<hr style={{ backgroundImage: `url(${hrImg})` }} />
			<P>
				Building an app is one of the most cost-effective ways to market your
				brand and generate revenue. Even small business owners can have a custom
				app to promote their products and services. Find out how we can create
				one at an affordable price.
			</P>
		</li>
		<li className="android-service">
			<H4>Complete Customization</H4>
			<hr style={{ backgroundImage: `url(${hrImg})` }} />
			<P>
				Form, function, and fabulous attention! You want it all. If you use a
				do-it-yourself app creator, the results will fall short of your goals.
				Turn to our android app development company. We design and deploy apps
				that are reliable and attention-grabbing.
			</P>
		</li>
		<li className="android-service">
			<H4>Expand Your Reach</H4>
			<hr style={{ backgroundImage: `url(${hrImg})` }} />
			<P>
				If you run a paid ad, not everyone is going to see it. However, with an
				android app, you’ll reach people around the world. In fact, customized
				apps allow businesses to effectively expand their reach into new markets
				and, most importantly, grow their profits.
			</P>
		</li>
		<li className="android-service">
			<H4>Enhance the User Experience</H4>
			<hr style={{ backgroundImage: `url(${hrImg})` }} />
			<P>
				It’s time to excite your customers. Today, apps take advantage of the
				newest technologies, such as artificial intelligence, virtual reality,
				and augmented reality. Don’t get left behind using yesterday’s
				technology. Reach out to <b>PixelTeh</b> today!
			</P>
		</li>
	</StyledServicesList>
);

export default ServicesList;
