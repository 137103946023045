import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import H3 from '../../../_common/H3';
import P from '../../../_common/P';
import { media, fonts, colors } from '../../../../globalStyles';
import AnimatedOnScroll from './../../../../components/_common/AnimatedOnScroll';
import leftArrow from '../../../../assets/img/left-arrow.png';

const StyledStepItem = styled.div`
	display: flex;
	justify-content: center;
	${media.maxSm`flex-direction: column;`}
	&:not(:last-of-type) {
		padding-bottom: 80px;
		${media.maxSm`padding-bottom: 20px;`}
	}
	.step-item {
		overflow: hidden;
		&>div {
			position: relative;
			display: flex;
			${media.maxSm`
			    justify-content: center;
			    width: 100%;
			`}
		}
		&:first-of-type {
			position: relative;
			width: 42%;
			flex-shrink: 0;
			${media.tablet`
				width: 46%;
			`}
			${media.maxSm`
				width: 100%;
			    display: flex;
			    flex-direction: column;
			    align-items: center;
			`}
		}
		.num {
			flex: 0 0 70%;
			color: ${colors.whiteGrey};
			font-family: ${fonts.openSans};
			font-size: 200px;
			font-weight: 400;
			line-height: 200px;
			${media.maxSm`
				font-size: 140px;
                line-height: 140px;
                flex: 0 0 auto;
			`}
		}
		${H3} {
			display: flex;
			align-items: center;
			margin-left: -46%;
			color: ${colors.blackTitle};
			font-family: ${fonts.loveloBlack};
			font-size: 28px;
			font-weight: 400;
			line-height: 32px;
			text-transform: uppercase;
			${media.laptop`
				margin-left: -50%;
			`}
			${media.laptopS`
				margin-left: -58%;
				font-size: 20px;
			`}
			${media.maxSm`
				position: absolute;
				top: 70px;
				justify-content: center;
				width: 100%;
				margin-left: 0;
			`}
			img {
				position: relative;
				margin-left: 27px;
				margin-bottom: 5px;
			}
		}
		${P} {
			color: ${colors.greyItalicTitle};
			text-align: left;
			padding: 40px 0 0 1px;
			font-family: ${fonts.openSans};
			font-size: 15px;
			line-height: 30px;
			a {
				color: ${colors.greyItalicTitle};
			}
		}
	}
	&.process-step-revers {
		${media.minSm`
			flex-direction: row-reverse;;
	`}
		.step-item {
			&>div {
				flex-direction: row-reverse;
			}
			.num {
				text-align: right;
			}
			${H3} {
				margin-right: -70%;
				flex-direction: row-reverse;
				${media.maxSm`
					margin-right: 0;
				`}
				img {
					transform: rotate(-180deg);
					margin-left: 0;
					margin-right: 27px;
				}
			}
		}
	}
	
`;

const StepItem = ({ revers, number, title, text }) => {
	return (
		<StyledStepItem
			className={!revers ? 'process-step' : 'process-step-revers'}
		>
			<div className="step-item">
				<AnimatedOnScroll animateIn="fadeInLeft" animateOut="fadeOutLeft">
					<div className="num">{number}</div>
					<H3>
						{title}
						<img
							className="hidden-sm"
							src={leftArrow}
							alt="orange arrow pointing right"
						/>
					</H3>
				</AnimatedOnScroll>
			</div>

			<div className="step-item">
				<AnimatedOnScroll animateIn="fadeInRight" animateOut="fadeOutRight">
					{text}
					{/*<P dangerouslySetInnerHTML={{ __html: text }} />*/}
				</AnimatedOnScroll>
			</div>
		</StyledStepItem>
	);
};

StepItem.propTypes = {
	revers: PropTypes.bool,
	number: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
};
export default StepItem;
