import React, { Fragment } from 'react';
import ContentSection from '../../ContentSection';

import Steps from './Steps';
import ContentSectionTopBorder from '../../_common/ContentSectionTopBorder';

const ProcessList = () => (
	<Fragment>
		<ContentSection
			title="What Does It Take to Develop an App?"
			subtitle="Creativity, experience, and a whole lot of code! We are experts in all those areas."
			noTopImage={true}
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		>
			<Steps />
		</ContentSection>
		<ContentSectionTopBorder />
	</Fragment>
);

export default ProcessList;
