import React from 'react';
import styled from 'styled-components';

import StepItem from './StepItem';
import { stepsData } from './stepsData';
import { media } from '../../../../globalStyles';

const StyledSteps = styled.div`
	max-width: 1350px;
	padding-top: 72px;
	margin: 0 auto;
	${media.maxSm`
		padding-top: 0;
	`}
`;

const Steps = () => (
	<StyledSteps>
		{stepsData.map(item => (
			<StepItem
				key={item.number}
				revers={item.reverse}
				number={item.number}
				title={item.title}
				text={item.text}
			/>
		))}
	</StyledSteps>
);

export default Steps;
