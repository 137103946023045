import React from 'react';
import { Link } from 'gatsby';
import P from '../../../_common/P';

export const stepsData = [
	{
		reverse: false,
		number: '01',
		title: 'Imagineering Phase',
		text: (
			<P>
				It all starts with a good idea. It may be yours, or ours, or a
				combination of both. We believe a great imagination leads to a great
				app. And we can help you get there. First we determine the demographics,
				behavior patterns, and goals of your target audience. This is critical
				when developing a customized app. Here, we conduct substantial research
				and brainstorming, because we want your app to have unique features that
				make it stand out in the market.
			</P>
		),
	},
	{
		reverse: true,
		number: '02',
		title: 'Time for a Wireframe',
		text: (
			<P>
				Let’s get back to the drawing board. Once we determine your needs and
				have a good concept in place, we begin creating app visuals with
				wireframes and sketches. Doing so not only helps you get a picture of
				the function of your app, but it helps us assess whether the concept,
				functions, and mechanisms for the application is feasible or not. By the
				end of this phase, we will have worked with you to modify and improve
				your app so that is ready for the design phase.
			</P>
		),
	},
	{
		reverse: false,
		number: '03',
		title: 'The Design Work Begins',
		text: (
			<P>
				Here’s where we make your idea come to life. It’s not enough to have a
				ground-breaking concept and a workable wire frame. You need to have an
				app that looks good and entices the end-user. In this phase, we consider
				both the user interface (UI) and user experience (UX) when creating the
				design elements. This builds the app’s persona by keeping in mind the
				user’s preference. The design phase is a multi-step process for drawing
				clear visual directions and offering an abstract of the final product.
			</P>
		),
	},
	{
		reverse: true,
		number: '04',
		title: 'Coding Creation',
		text: (
			<P>
				Get ready! It’s time to code. After determining what you need, the
				design, and your goals, our android developers get to work. Along with
				creating apps for Android devices, we also do{' '}
				<Link to="/technologies-crossplatform">cross-platform development</Link>
				. In fact, most of our Android apps work on iPhones as well. During this
				phase, your app goes through several steps. Selecting the right approach
				for developing an app is highly important. Our approach to Android
				application development is dependent on your time and budget
				constraints. In the end, you’ll have a personalized app the performs and
				impresses!
			</P>
		),
	},
	{
		reverse: false,
		number: '05',
		title: 'Testing & Deployment',
		text: (
			<P>
				Finally, it’s time to test and release your app. Here, we eliminate any
				bugs, modify the apps functionality or appearance if necessary, and
				ensure everything works perfectly. Even though we perform a
				comprehensive check before deployment, throughout the entire process we
				are always testing for usability, interface and security checks, stress,
				compatibility, and performance. By the time we come to this stage, it’s
				usually a matter of tweaking and fine tuning everything. Most
				importantly, after we deploy your customized app, we remain available in
				the future for maintenance and support.
			</P>
		),
	},
];
