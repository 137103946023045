import React from 'react';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import styled from 'styled-components';
import { media } from '../../globalStyles';

const StyledDescriptionBlock = styled.div`
	.content-section-top-border {
		${media.maxSm`
		display: none;
	`}
	}
	${P} {
		max-width: 1059px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 0;
	}
`;

const DescriptionBlock = () => (
	<StyledDescriptionBlock>
		<PageDescription
			title="Capture Attention with a Customized Android App"
			subtitle={
				'<b>PixelTeh</b> builds tailored android apps that make you stand out from others.'
			}
			bgColor="white"
			paddingBottom={0}
			textPaddingBottom={0}
			seoTitle={'h2'}
			seoSubTitle={'h3'}
		/>
	</StyledDescriptionBlock>
);

export default DescriptionBlock;
