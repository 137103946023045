import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import P from '../../_common/P';
import H4 from '../../_common/H4';
import { media, fonts, colors } from '../../../globalStyles';
import AnimatedOnScroll from '../../_common/AnimatedOnScroll';

const StyledProjectItem = styled.li`
	width: 18.8%;
    text-align: center;
    margin: 0 42px;
	${media.laptopS`
		width: 22%;
		margin: 0 10px;
	`};
	${media.maxSm`
		position: relative;
	    padding-bottom: 40px;
	    background-color: ${colors.blueDark};
	    border-radius: 16px;
	    box-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.3);
	    margin-bottom: 40px;
	    flex-direction: column;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    margin-left: 0;
	    margin-right: 0;
	    width: 48%;
	    height: 209px;
	`};
		${media.maxXs`
			width: 262px;
	`};
	}
	${H4} {
		margin-bottom: 10px;
		font-family: ${fonts.loveloBlack};
		font-size: 15px;
		font-weight: 600;
		line-height: 24px;
		text-transform: uppercase;
		padding-bottom: 5px;
		color: ${colors.blackTitle};
		text-align: center;
		${media.tablet`
			font-size: 13px;
	`};
		${media.maxSm`
			color: ${colors.white};
		    font-size: 15px;
		    width: 100%;
	`};
	}
	${P} {
		    color: ${colors.greyItalicTitle};
		    font-family: ${fonts.robotoLightItalic};
		    font-size: 15px;
		    font-style: italic;
		    line-height: 24px;
		    padding-top: 52px;
		    text-align: center;
		    ${media.maxSm`display: none!important;`}
	}
	figure img {
		width: 100%;
	}
	.visible-sm {
		max-width: 80px;
	    height: 80px;
	    margin: 48px 0 20px;
	}
`;
const ProjectItem = ({ name, description, mainImg, mobileImg }) => {
	return (
		<StyledProjectItem>
			<figure className="visible-sm">
				<img src={mobileImg} alt="" />
			</figure>
			<AnimatedOnScroll animateIn="fadeInDown" animateOut="fadeOutUp">
				<H4>{name}</H4>
			</AnimatedOnScroll>
			<AnimatedOnScroll animateIn="flipUp" animateOut="flipDown">
				<figure className="hidden-sm">
					<img src={mainImg} alt="" />
				</figure>
			</AnimatedOnScroll>
			<AnimatedOnScroll animateIn="fadeInDown" animateOut="fadeOutUp">
				<P>{description}</P>
			</AnimatedOnScroll>
		</StyledProjectItem>
	);
};

ProjectItem.propTypes = {
	name: PropTypes.string,
	description: PropTypes.string,
	mainImg: PropTypes.string,
	mobileImg: PropTypes.string,
};

export default ProjectItem;
